import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Section from "../components/Section"
import Figure from "../components/Figure"
import { H1, H2, H3, P } from "../components/VerticalRhythm"
import { Flex, Box } from "rebass"

import PictureSet from "../components/PictureSet"
import imgObj05 from "../imgObjs/imgObj05"
import imgObj06 from "../imgObjs/imgObj06"
import imgGM from "../img/0600/3x4/Geesche-Martin.jpg"

import breakpoints from "../theme/breakpoints"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Vita"
      keywords={[`Hebamme`, `Frankfurt`, `Beratung`, `Geesche Martin`]}
    />
    <Figure caption="">
      <PictureSet imgObj={imgObj05} />
    </Figure>
    <Section>
      <Flex flexDirection={"row-reverse"}>
        <Box width={[1, 1, 1 / 2]}>
          <P textAlign={"right"}>
            <em>
              „Man sieht nur mit dem Herzen gut. Das Wesentliche ist für die
              Augen unsichtbar.“
            </em>
            <br />— Antoine de Saint-Exupéry
          </P>
        </Box>
      </Flex>
      <H1>Vita — mein Weg</H1>
      <Flex flexDirection={"row"} flexWrap={"wrap"}>
        <Box width={[1, 1, 1 / 2, 2 / 3]}>
          <P>
            Nach meinem Examen zur Hebamme 1986 in Göttingen arbeitete ich in
            verschiedenen Städten — sowohl Krankenhäusern, als auch
            freiberuflich. Seit 1997 lebe ich in Frankfurt und gründete hier
            meine Familie.
          </P>
          <P>
            Ich berate Frauen und Paare in der Geburtsvorbereitung und in der
            Nachsorge zu Hause. In einer Praxisgemeinschaft berate ich seit 2002
            werdende Mütter und Paare bei Problemen und Beschwerden in der
            Schwangerschaft und nach schweren Geburten.
          </P>
          <P>
            In Krisensituationen habe ich über mehrere Jahre Frauen, Paare und
            Familien bei der Caritas beraten und begleitet.
          </P>
          <P>
            Meine Hebammentätigkeit in Krankenhäusern habe ich 2018 beendet.
          </P>

          <H3>Master of Acupuncture</H3>
          <P>
            2005 begann ich mich mit Akupunktur zu beschäftigen, da sie eine
            gute Möglichkeit bietet, Beschwerden unterschiedlichster Art in und
            nach der Schwangerschaft sehr wirksam zu behandeln. Nach einer
            Grundausbildung, folgte eine umfassende Ausbildung in Akupunktur.
            Seit 2013 trage ich den Titel „Master of Acupuncture“.
          </P>
          <P>
            Seitdem unterstützt mich die Akupunktur erfolgreich beim Behandeln
            der unterschiedlichen Schwangerschaftsbeschwerden. Viele Beschwerden
            können damit gelindert oder beseitigt werden. Mit der Akupunktur
            bereite ich die Frauen auch effektiv auf das Geburtsereignis vor.
          </P>
          <P>
            Neben der Akupunktur begeistert und erfüllt mich die Beratung sehr.
            Aufgrund des zunehmenden Beratungsbedarfs in Krisensituationen nimmt
            sie einen immer größeren Anteil meiner Tätigkeit ein.
          </P>
          <P>
            Immer mehr entstand in den letzten Jahren der Wunsch, mein
            Fachwissen auf ein noch breiteres Fundament zu stellen.
          </P>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} pl={[0, 0, 4]}>
          <img src={imgGM} alt="" />{" "}
        </Box>
      </Flex>
    </Section>

    <Figure id={"master"} caption="">
      <PictureSet imgObj={imgObj06} />
    </Figure>

    <Section>
      <H3>Master M.A. Psychosoziale Beratung</H3>
      <P>
        An der University of Applied Sciences in Frankfurt studierte ich ab 2015
        im Studiengang „Psychosoziale Beratung und Recht“ Fachrichtung „Beratung
        und Psychotherapie“ und schloss als Master of Arts im Februar 2018 mit
        sehr gutem Ergebnis ab.
      </P>
      <P>
        Das Studium vermittelt umfassende Beratungskompetenzen. Dies ermöglicht
        es mir, meine langjährige Erfahrung im Bereich der Beratung von Frauen,
        Paaren und Familien, ergänzt um das akademische Fachwissen, noch
        kompetenter anwenden zu können.
      </P>
      <P>
        In meiner Masterthese habe ich mich mit schweren und traumatischen
        Geburten beschäftigt. Dieses spezifische Wissen ermöglicht es mir,
        betroffenen Frauen und Paaren bei der Aufarbeitung ihres
        Geburtserlebnisses hilfreich zur Seite zu stehen.
      </P>
      <P>
        Seit 2018 berate ich auch Frauen und Männer in Krisensituationen, die
        nicht im Kontext Schwangerschaft oder Geburt stehen. Mehr Infos finden
        Sie auf der Seite{" "}
        <a href="http://krisenberatung-frankfurt.com/">
          krisenberatung-frankfurt.com
        </a>
        .
      </P>
      <H3>Zusätzliche Qualifikationen</H3>
      <P>
        „Akute Traumatisierung in Beratung und Therapie“ — Seminar, Institut für
        Traumabearbeitung und Weiterbildung, Frankfurt am Main
      </P>
      <P>„Trauern – Wie geht das?“ — Fachtag, Haus am Dom, Frankfurt am Main</P>
      <P>
        „Trauer und belastende Lebenssituationen“ — Fachtag, Haus am Dom,
        Frankfurt am Main
      </P>
      <P>
        „Tot – Abschiede und Neuanfänge“ — Workshop, Haus am Dom, Frankfurt am
        Main
      </P>
      <P>
        „Die Zeit heilt nicht alle Wunden“ — Seminar, Caritas, Frankfurt am Main
      </P>
      <P>
        „Emotionale Stabilität gewinnen und dauerhaft bewahren trotz
        destruktiver Beziehungen im Privat- und Berufsleben“ — Therapeutisches
        Gruppenseminar, Essen
      </P>
      <P>
        "Wie sage ich es? — Die Konfrontation in der Beratung" — Seminar,
        Caritas, Frankfurt am Main
      </P>
      <P>
        "Verarbeiten von Verlusten" — Hospitation, Trauerzentrum, Frankfurt am
        Main
      </P>
    </Section>
  </Layout>
)

export default IndexPage
